import React, { useState } from 'react'
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Menu,
  MenuItem
} from '@mui/material'
import { Menu as MenuIcon, X, User } from 'react-feather'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import { styled } from '@mui/material/styles'
import { withRouter } from '../../utils/customHoc'

const navigationMenuItems = [
  {
    title: <><i>wrkxfmly</i> Home</>,
    link: '/',
  },
  {
    title: 'National Collection',
    link: '/collection',
    pathname: 'national-photos'
  },
  {
    title: 'COVID-19 Collection',
    link: '/covid-collection',
    pathname: 'covid-collection'
  },
  {
    title: 'Log In',
    link: '/login',
    pathname: 'login',
    icon: <User size={18} strokeLinecap='square' strokeWidth='3px' />
  }
]

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    left: '0px !important',
    float: 'left',
    borderRadius: 0,
    marginTop: { xs: theme.spacing(0), sm: theme.spacing(1) },
    width: '100%',
    maxWidth: '100%',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px'
  }
}))

const MobileMenuPublic = ({ userStore }) => {
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [open, setOpen] = useState(false)

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleClick = () => {
    setOpen(!open)
  }

  const style = {
    menuItem: {
      fontSize: '1.25rem',
      fontFamily: `'Calibre', Helvetica, Verdana, sans-serif`,
      lineHeight: 1.2,
      textTransform: 'none',
      color: '#000',
      ':hover': {
        color: '#8e5ba6 !important'
      }
    }
  }

  return (
    <>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size='large'
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleOpenNavMenu}
          sx={{minHeight: '3.5rem'}}
        >
          {anchorElNav ? <X /> : <MenuIcon />}
        </IconButton>
        <StyledMenu
          id='menu-appbar'
          anchorEl={anchorElNav}
          keepMounted
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', md: 'none' },
            width: '100%'
          }}
        >
          {navigationMenuItems.map(page => (
            <MenuItem
              key={page.title}
              sx={{
                backgroundColor: 'transparent !important'
              }}
            >
              {page.child ? (
                <List
                  key={`${page.child}-list`}
                  sx={{ width: '100%' }}
                  component='div'
                  aria-labelledby='nested-list-subheader'
                >
                  <ListItemButton
                    onClick={handleClick}
                    sx={{ backgroundColor: 'transparent !important', width: 'fit-content' }}
                  >
                    <ListItemText
                      primary={
                        <Typography sx={{ ...style.menuItem, color: open ? '#8e5ba6' : '#000' }}>
                          {page.title}
                          {open ? <ArrowDropUp /> : <ArrowDropDown />}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  <Collapse in={open} timeout='auto' unmountOnExit>
                    <List component='a' disablePadding>
                      {page.child.map(childPage => (
                        <ListItemButton key={`${childPage.titel}-child-item`} sx={{ pl: 4 }}>
                          <ListItemText
                            primary={
                              <Link to={childPage.link} key={`page-title-link-${childPage.title}`}>
                                <Typography sx={{ ...style.menuItem }}>
                                  {childPage.title}
                                </Typography>
                              </Link>
                            }
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </List>
              ) : (
                <Link to={page.link} key={`page-title-link-${page.title}`}>
                  <Typography
                    textAlign='center'
                    sx={{
                      ml: { xs: 2, sm: 3 },
                      svg: { verticalAlign: 'middle', mb: '2px' },
                      ...style.menuItem
                    }}
                  >
                    {page.icon ? page.icon : null} {page.title}
                  </Typography>
                </Link>
              )}
            </MenuItem>
          ))}
        </StyledMenu>
      </Box>
    </>
  )
}

export default inject('userStore')(observer(withRouter(MobileMenuPublic)))
