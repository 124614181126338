import { Box, Grid } from '@mui/material'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import { Award, Download, Shield } from 'react-feather'
import { getFileTitleForDownload } from '../../../../utils/commonFunctions'
import DownloadUtils from '../../../../utils/DownloadUtils'
import GATag from '../../../../utils/GATag'
import { Typography } from '@mui/material'
import variables from '../../../settings/_variables.scss'

const PhotoModalFooter = props => {
  const { photo, role } = props
  const todayDate = moment().format('YYYY-MM-DD')
  const [cohortEndDate] = useState(
    photo ? moment(photo.project.cohort.endDate, 'YYYY-MM-DD') : null
  )

  const getFinalAward = () => {
    if (photo && photo.awards && photo.awards.length > 0) {
      let aoeCount = photo.awards.filter(award => award.awardClass === 'Award of Excellence').length
      let hmCount = photo.awards.filter(award => award.awardClass === 'Honorable Mention').length

      return aoeCount > hmCount || aoeCount === hmCount ? 'aoe' : hmCount > 0 ? 'hm' : null
    }
    return null
  }

  const [finalAward] = useState(getFinalAward())

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'

    const k = 1000
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  const handleDownload = async rawOrOriginal => {
    const { downloadUrl, url, originalTitle, createdAt, exifData, rawFileUrl } = photo
    const { fileType } = exifData

    const year = moment(cohortEndDate).format('YYYY')
    const name =
      photo &&
      photo.project &&
      photo.project.student &&
      photo.project.student.name &&
      photo.project.student.name
        .trim()
        .replace(/\s+/g, ' ')
        .split(' ')
        .join('-')
        .toLowerCase()
    let fileTitle = ''
    if (originalTitle) {
      const tempTitle = getFileTitleForDownload(originalTitle)
      fileTitle = (tempTitle.length > 100 ? (tempTitle.substring(0, 100)).slice(0, -1)  :  tempTitle) + '-'
    }
    let photoTitle = `working-assumptions-${name}-${fileTitle}${year}.${
      rawOrOriginal === 'raw' ? 'raw' : fileType
    }`
    let fileDownloadUrl = rawOrOriginal === 'raw' ? rawFileUrl : downloadUrl
    GATag.setEvent({
      category: 'image',
      action: 'downloaded-footer',
      label: 'downloaded footer',
      value: 1
    })
    if (!url.includes('base64')) {
      DownloadUtils.downloadFileAndSaveAs(fileDownloadUrl, photoTitle)
    } else {
      window.open(url.replace(/^data:image\/[^;]+/, 'data:application/octet-stream'))
    }
  }

  const generateDownloadJSX = () => {
    return (
      <Fragment>
        {photo &&
        [('rawFileUrl', 'url', 'originalUrl', 'cdnOriginalUrl', 'downloadUrl')].some(URL =>
          Object.keys(photo).includes(URL)
        ) ? (
          <>
            {getFormattedLabel('Download Photograph')}
            {['url', 'originalUrl', 'cdnOriginalUrl', 'downloadUrl'].some(URL =>
              Object.keys(photo).includes(URL)
            ) ? (
              <Typography
                variant='link'
                className='large'
                onClick={() => handleDownload('original')}
              >
                <span className='icon-text-span'>
                  <Download size={18} />
                  {photo.exifData ? photo.exifData.fileType.toUpperCase() : ''}{' '}
                  {formatBytes(photo.originalFileSize)}
                </span>
              </Typography>
            ) : null}
            {/* {photo.rawFileUrl !== 'No attachment' ? (
                  <div className='download-file__links--link'>
                    <h3
                      className='h3--semi-strong link'
                      onClick={() => this.handleDownload('raw')}
                    >
                      <span className='icon-text-span'>
                        <Download size={18} />
                        {'  '} RAW {this.formatBytes(photo.rawFileSize)}
                      </span>
                    </h3>
                  </div>
                ) : null} */}
          </>
        ) : null}
      </Fragment>
    )
  }

  const getFormattedLabel = label => {
    return (
      <Typography variant='dataLabel' component='p'>
        {label}
      </Typography>
    )
  }

  const getFormattedContent = content => {
    return (
      <Typography
        variant='dataLabel'
        className='content icon-text-span'
        component='p'
        sx={{ svg: { verticalAlign: 'middle' } }}
      >
        {content}
      </Typography>
    )
  }

  return (
    <Box sx={{ padding: '3rem', backgroundColor: variables.backgroundGrey }}>
      <Typography variant='h2' className='strong' sx={{ mb: 4 }}>
        More About This Photograph
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={4} sm={6} xs={12}>
          {generateDownloadJSX(photo)}
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          {getFormattedLabel('Award Status')}
          {photo &&
          cohortEndDate &&
          photo.project &&
          photo.project.cohort &&
          !photo.project.cohort.reviewed
            ? cohortEndDate.isBefore(todayDate) &&
              !photo.project.cohort.reviewed &&
              photo.project.submitted
              ? getFormattedContent('Project Is Being Reviewed')
              : !cohortEndDate.isBefore(todayDate) ||
                cohortEndDate.isSame(todayDate) ||
                (photo &&
                  photo.project &&
                  !photo.project.submitted &&
                  (!cohortEndDate.isBefore(todayDate) || cohortEndDate.isSame(todayDate)))
              ? getFormattedContent('Project Not Reviewed')
              : null
            : (photo &&
                photo.summarySent &&
                ['student', 'teacher', 'facilitator'].includes(role)) ||
              (['appadmin', 'curator'].includes(role) &&
                photo &&
                photo.project &&
                photo.project.cohort &&
                photo.project.cohort.reviewed) ||
              (['curator'].includes(role) &&
                photo &&
                photo.project &&
                !photo.project.cohort.reviewed)
            ? photo && photo.awards.length > 0
              ? finalAward === 'aoe'
                ? getFormattedContent(
                    <>
                      <Award size={19} />
                      Award of Excellence
                    </>
                  )
                : finalAward === 'hm'
                ? getFormattedContent(
                    <>
                      <Shield size={19} />
                      Honorable Mention
                    </>
                  )
                : null
              : getFormattedContent('No Awards')
            : photo &&
              !photo.summarySent &&
              photo.project.cohort.reviewed &&
              ['student', 'teacher', 'facilitator'].includes(role)
            ? getFormattedContent('Project Is Being Reviewed')
            : null}
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          {getFormattedLabel('Project Submitted On')}
          {getFormattedContent(
            photo && photo.project.submitted
              ? moment(photo.project.submittedUpdatedAt).format('LL')
              : 'Project Not Submitted'
          )}
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          {getFormattedLabel('Photograph Published On')}
          {getFormattedContent(
            photo && photo.published && photo.adminApproved
              ? moment(photo.publishUpdatedAt).format('LL')
              : 'Photograph Not Published'
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default PhotoModalFooter
