import { useEffect, useState } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { Box, Grid } from '@mui/material'
import { inject, observer } from 'mobx-react'
import { getTextContentByPage } from '../../content/textContent'
import ListSearchBar from '../elements/ListSearchBar'
import Loading from '../elements/Loading'
import CuratorsUpcomingReviews from '../molecules/CuratorsUpcomingReviews'
import EmptyListDesign from '../molecules/EmptyListDesign.js'
import ReviewListsIntroductions from '../molecules/ReviewListsIntroductions'
import ReviewList from './ReviewList'
import { PAGINATED_COHORTS_NEDDING_REVIEW } from '../../queries/reviewQueries'
import { useQuery } from '@apollo/client'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const BATCH_SIZE = 9

const InProgressReviews = props => {
  const [cohorts, setCohorts] = useState(null)
  const [cohortCount, setCohortCount] = useState(0)
  const [searchKey, setSearchKey] = useState('')
  const [currentFilter, setCurrentFilter] = useState(props.filter)
  const [reviewType, setReviewType] = useState(props.reviewType)
  const [step, setStep] = useState(0)
  const [batchSize] = useState(BATCH_SIZE)
  const [baseBatchSize] = useState(BATCH_SIZE)
  const [totalPages, setTotalPages] = useState(0)

  const navigate = useNavigate()
  const location = useLocation()

  const { data, loading } = useQuery(PAGINATED_COHORTS_NEDDING_REVIEW, {
    variables: {
      step: step,
      baseBatchSize: baseBatchSize,
      batchSize: batchSize,
      search: searchKey,
      filter: currentFilter,
      reviewType: reviewType
    },
    onCompleted(data) {
      setCohorts(data.inProgressReviewsConnection)
      setCohortCount(data.inProgressReviewsCount)
    },
    fetchPolicy: 'network-only' // Use this fetch policy if you want to always fetch data from the network
  })

  const handlePageChange = page => {
    setStep(page)
    // Update the URL
    const pathParts = location.pathname.split('/').filter(Boolean)
    if (!isNaN(pathParts[pathParts.length - 1])) {
      pathParts.pop()
    }
    const newPath = `/${pathParts.join('/')}/${page + 1}`
    navigate(newPath)
  }

  useEffect(() => {
    setStep(0)
  }, [searchKey])

  useEffect(() => {
    setStep(isNaN(props.page) ? 0 : props.page - 1)
    setCurrentFilter(props.filter)
    setReviewType(props.reviewType)
  }, [props])

  useEffect(() => {
    setTotalPages(Math.ceil(cohortCount / batchSize))
  }, [batchSize, cohortCount])

  const { role, profileId } = props.userStore
  const placeHolderText =
    reviewType === 'submission'
      ? 'Class in Submission Review'
      : reviewType === 'curatorial'
      ? 'Class in Curatorial Review'
      : reviewType === 'award'
      ? 'Class in Award Summary Review'
      : reviewType === 'publishing'
      ? 'Class in Publishing Review'
      : reviewType === 'completed'
      ? 'Class with Completed Review'
      : null

  return (
    <Box sx={{ mt: { xs: 5, md: 6, lg: 10 }, pb: { xs: 4 } }}>
      <Box sx={{ px: { xs: 3, sm: 4, md: 6 } }}>
        {role === 'curator' ? <CuratorsUpcomingReviews /> : null}
        <Box sx={{ mb: { xs: 5 } }}>
          <Grid container columnSpacing={2}>
            <Grid item md={8} sm={12} xs={12}>
              <ReviewListsIntroductions
                reviewType={reviewType}
                cohortCount={cohortCount}
                role={role}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <ListSearchBar
                placeHolder={'Search for a ' + placeHolderText}
                id='review-search'
                onChange={e => setSearchKey(e.target.value)}
                value={searchKey}
                style={{ mt: { md: '0.6px' } }}
              />
            </Grid>
          </Grid>
        </Box>
        {loading ? (
          <Box className='reivew-list-loader--wrapper'>
            <Loading />
          </Box>
        ) : null}
      </Box>
      {cohortCount <= 0 &&
        (!searchKey ? (
          <EmptyListDesign
            heading={getTextContentByPage(
              'REVIEW_DEFAULT',
              props.role.toUpperCase(),
              'INPROGRESS_EMPTYINTRO'
            )}
            subheading={getTextContentByPage(
              'REVIEW_DEFAULT',
              props.role.toUpperCase(),
              'INPROGRESS_EMPTYSUBHEADING'
            )}
          />
        ) : (
          <EmptyListDesign
            heading={getTextContentByPage(
              'REVIEW',
              props.role.toUpperCase(),
              'INPROGRESS_EMPTYINTRO'
            )}
            subheading={getTextContentByPage(
              'REVIEW',
              props.role.toUpperCase(),
              'INPROGRESS_EMPTYSUBHEADING'
            )}
          />
        ))}
      {cohortCount > 0 && (
        <ReviewList
          reviewType={props.reviewType}
          cohorts={cohorts}
          role={role}
          profileId={profileId}
          totalPages={totalPages}
          page={step}
          setPage={handlePageChange}
        />
      )}
    </Box>
  )
}

export default inject('userStore')(observer(withApollo(InProgressReviews)))
