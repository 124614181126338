import { useMutation } from '@apollo/client'
import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getApprovedEntityCount, getFormattedStatus } from '../../../../utils/commonFunctions'
import Button from '../../../elements/Button'
import FormCheckbox from '../../../elements/FormCheckbox'
import Switches from '../../../elements/Switches'
import {
  PUBLISH_PHOTO_MUTATION,
  TOGGLE_ADMIN_APPROVAL_MUTATION
} from '../../../../queries/PhotoDetailsQueries'

const PhotoPublishSection = props => {
  const { photoId, photoDetails, role, formIsValid } = props
  const [nationalCollection, setNationalCollection] = useState(
    photoDetails && photoDetails.adminApproved
      ? photoDetails.adminApproved
      : photoDetails && !photoDetails.published && photoDetails.readyToPublish
      ? true
      : false
  )
  const [covidCollection, setCovidCollection] = useState(
    photoDetails && photoDetails.covidRelated ? photoDetails.covidRelated : false
  )

  const [readyToPublish, setReadyToPublish] = useState(
    photoDetails && photoDetails.readyToPublish ? photoDetails.readyToPublish : false
  )

  const [isPublished, setIsPublished] = useState(
    photoDetails && photoDetails.published ? photoDetails.published : false
  )

  useEffect(() => {
    setNationalCollection(
      props.photoDetails && props.photoDetails.adminApproved
        ? props.photoDetails.adminApproved
        : props.photoDetails && !props.photoDetails.published && props.photoDetails.readyToPublish
        ? true
        : false
    )
    setReadyToPublish(
      props.photoDetails && props.photoDetails.readyToPublish
        ? props.photoDetails.readyToPublish
        : false
    )
    setCovidCollection(
      props.photoDetails && props.photoDetails.covidRelated
        ? props.photoDetails.covidRelated
        : false
    )
    setIsPublished(
      props.photoDetails && props.photoDetails.published ? props.photoDetails.published : false
    )
  }, [props.photoId])

  const [toggleAdminApprovalMutation, { loading, data, error }] = useMutation(
    TOGGLE_ADMIN_APPROVAL_MUTATION,
    {
      onCompleted(data) {
        props.refetchPhoto()
      }
    }
  )

  const [
    publishPhotoMutation,
    { loading: pubLoading, data: pubData, error: pbuError }
  ] = useMutation(PUBLISH_PHOTO_MUTATION, {
    onCompleted(data) {
      props.refetchPhoto()
    }
  })
  const enableRedyToPublish = photoDetails
    ? !['', null, undefined].includes(photoDetails.editedTitle) &&
      !['', null, undefined].includes(photoDetails.editedCaption) &&
      ((photoDetails.hasPeople &&
        photoDetails.totalMrRequired === getApprovedEntityCount(photoDetails.modelReleases, 'mr') &&
        getApprovedEntityCount(photoDetails.useAgreements, 'ua') > 0) ||
        (photoDetails.hasPeople === false &&
          getApprovedEntityCount(photoDetails.useAgreements, 'ua') > 0))
    : false

  const shouldShow =
    photoDetails &&
    photoDetails.submitted &&
    photoDetails.project.cohort.reviewed &&
    photoDetails.awards.length > 0 &&
    role === 'appadmin'
  return shouldShow ? (
    <Grid
      container
      className='award-nominator__publish-photograph'
      sx={{ pt: { xs: 2 }, pb: { xs: 8, lg: 10 } }}
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant='h2' className='strong'>
          Publish This Photograph
        </Typography>
        {photoDetails.editedTitle == null || photoDetails.editedCaption == null
          ? getFormattedStatus(
              'AlertTriangle',
              'This photograph needs an edited title and caption before it can be published.'
            )
          : null}
        <Typography variant='paragraph' component={'p'} sx={{ mt: { xs: 3 } }}>
          Set as ready to publish when you have reviewed all details and approved all forms for this
          photograph.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: { xs: 0.4 } }}>
        <Switches
          checked={readyToPublish}
          onChange={e => {
            setReadyToPublish(e.target.checked)
            setNationalCollection(e.target.checked)
            toggleAdminApprovalMutation({
              variables: {
                photoId: photoId,
                isPublishable: e.target.checked
              }
            })
          }}
          disabled={!formIsValid || !enableRedyToPublish}
          name='readyToPublish'
          label='Ready to Publish'
        />

        <Typography variant='paragraph' component='p' sx={{ mt: { xs: 3.5 } }}>
          Which collection should this photograph appear in?
        </Typography>
      </Grid>
      <Grid item>
        <Box sx={{ display: { xs: 'grid', sm: 'flex' }, gap: { xs: 1, sm: 0 } }}>
          <FormCheckbox
            label='National Collection'
            color='primary'
            name='nationalCollection'
            disabled={!readyToPublish || (readyToPublish && isPublished)}
            checked={nationalCollection}
            onChange={e => setNationalCollection(e.target.checked)}
            labelPlacement='end'
          />
          <FormCheckbox
            label='COVID-19 Collection'
            color='primary'
            name='covidCollection'
            disabled={!readyToPublish || (readyToPublish && isPublished)}
            checked={covidCollection}
            onChange={e => setCovidCollection(e.target.checked)}
            labelPlacement='end'
          />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ mt: { xs: 2 } }}>
        {isPublished ? (
          <Button
            variant='contained'
            type='submit'
            title='Unpublish Photograph'
            onClick={e => {
              setIsPublished(false)
              publishPhotoMutation({
                variables: {
                  photoId: photoId,
                  nationalCollection: nationalCollection,
                  covidCollection: covidCollection,
                  opType: 'unpublish'
                }
              })
            }}
            sx={{
              width: { xs: '100%', sm: 'auto' }
            }}
            disabled={
              !formIsValid ||
              ['', null, undefined].includes(photoDetails.editedTitle) ||
              ['', null, undefined].includes(photoDetails.editedCaption)
            }
          />
        ) : (
          <Button
            variant='contained'
            type='submit'
            title='Publish Photograph'
            onClick={e => {
              setIsPublished(true)
              publishPhotoMutation({
                variables: {
                  photoId: photoId,
                  nationalCollection: nationalCollection,
                  covidCollection: covidCollection,
                  opType: 'publish'
                }
              })
            }}
            disabled={
              !formIsValid ||
              !readyToPublish ||
              (readyToPublish && !(nationalCollection || covidCollection)) ||
              ['', null, undefined].includes(photoDetails.editedTitle) ||
              ['', null, undefined].includes(photoDetails.editedCaption)
            }
            sx={{
              width: { xs: '100%', sm: 'auto' }
            }}
          />
        )}
      </Grid>
    </Grid>
  ) : null
}

export default PhotoPublishSection
