import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import { User } from 'react-feather'

const navigationMenuItems = [
  {
    title: <><i>wrkxfmly</i> Home</>,
    link: '/',
  },
  {
    title: 'Collections',
    link: null,
    pathname: 'collection',
    child: [
      {
        title: 'National',
        link: '/collection',
        pathname: 'national-photos',
      },
      {
        title: 'COVID-19',
        link: '/covid-collection',
        pathname: 'covid-collection',
      }
    ]
  },
  {
    title: 'Log In',
    link: '/login',
    pathname: 'login',
    icon: <User size={18} strokeLinecap='square' strokeWidth='3px' />
  },
]

const DesktopMenuPublic = props => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const style = {
    menuItem: {
      fontSize: '1rem !important',
      fontFamily: `'Calibre', Helvetica, Verdana, sans-serif !important`,
      lineHeight: 1.2,
      textTransform: 'none !important',
      cursor: 'pointer',
      color: '#000',
      ':hover': {
        color: '#8e5ba6'
      },
      marginRight: 2
    }
  }

  return (
    <Box sx={{ px: {md: 2}, position: 'absolute', right: '0', display: { xs: 'none', md: 'flex' } }}>
      {navigationMenuItems.map(page =>
        page.child ? (
          <Box key={`desktop-public-menu-item-${page.child.title}}`} sx={{
            display: { xs: 'none', md: 'flex' },
            flexGrow: 1,
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}>
            <Typography
              id={`${page.title.split(' ').join('-')}-button`}
              aria-controls={open ? `${page.title.split(' ').join('-')}-menu` : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{
                ...style.menuItem,
                color: open ? '#8e5ba6' : '#000',
                'svg' : {
                  mt: '-2px'
                }
              }}
            >
              {page.title}{open ? <ArrowDropUp/> : <ArrowDropDown/>}
            </Typography>
            <Menu
              id={`${page.title.split(' ').join('-')}-menu`}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': `${page.title.split(' ').join('-')}-button`,
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              getContentAnchorEl={null}
              sx={{top: 30, left: 50, display: { xs: 'none', md: 'flex' },}}
            >
              {page.child.map((children, index) =>
                <MenuItem key={`children-title-link-${children.title}`} sx={{borderTop: index ? '1px solid rgba(0, 0, 0, 0.12)' : '0', width: 150}}>
                  <Link to={children.link}>
                    <Typography sx={{...style.menuItem}}>{children.title}</Typography>
                  </Link>
                </MenuItem>
              )}
            </Menu>
          </Box>
        ) : (
          <Link
            key={`${page.title}-link`}
            to={page.link}

          >
            <Typography key={`${page.title}-btn`} sx={{...style.menuItem}}>
              {page.icon ? page.icon : null} {page.title}
            </Typography>
          </Link>
        )
      )}
    </Box>
  )
}

export default DesktopMenuPublic
