import moment from 'moment'
import { Typography } from '@mui/material'
import { getFormattedStatus } from '../utils/commonFunctions'
import DateUtils from '../utils/DateUtils'
const TEXT_CONTENT = {
  CLASSLIST_APPADMIN_UPCOMING_INTRO: 'These classes are scheduled but have not started yet.',
  CLASSLIST_TEACHER_UPCOMING_INTRO: (
    <>
      These classes are scheduled but have not started yet. If you need to change the dates for a
      class, please contact the <i>wrkxfmly</i> administrator.
    </>
  ),
  CLASSLIST_FACILITATOR_UPCOMING_INTRO: (
    <>
      These classes are scheduled but have not started yet. If you need to change the dates for a
      class, please contact the <i>wrkxfmly</i> administrator.
    </>
  ),

  CLASSLIST_APPADMIN_UPCOMING_EMPTYINTRO: 'There are no scheduled upcoming classes.',
  CLASSLIST_TEACHER_UPCOMING_EMPTYINTRO: 'You do not have any scheduled upcoming classes.',
  CLASSLIST_FACILITATOR_UPCOMING_EMPTYINTRO: 'You do not have any scheduled upcoming classes.',

  CLASSLIST_APPADMIN_UPCOMING_EMPTYSUBHEADING:
    'Once you or a teacher create a new class, it will appear here until the class starts.',
  CLASSLIST_TEACHER_UPCOMING_EMPTYSUBHEADING: (
    <>
      Once you or the <i>wrkxfmly</i> administrator create a class, it will appear here until the
      class starts.
    </>
  ),
  CLASSLIST_FACILITATOR_UPCOMING_EMPTYSUBHEADING:
    'Once you are assigned to a class, you will be notified by email, and the class will appear here.',

  CLASSLIST_APPADMIN_CURRENT_INTRO:
    'These classes are currently active. Students can upload work and submit projects.',
  CLASSLIST_TEACHER_CURRENT_INTRO: (
    <>
      These classes are currently active. Students can upload work and submit projects. If you need
      to extend the end date for a class, please contact the <i>wrkxfmly</i> administrator.
    </>
  ),
  CLASSLIST_FACILITATOR_CURRENT_INTRO: (
    <>
      These classes are currently active. Students can upload work and submit projects. If you need
      to extend the end date for a class, please contact the <i>wrkxfmly</i> administrator.
    </>
  ),

  CLASSLIST_APPADMIN_CURRENT_EMPTYINTRO: 'There are no current classes.',
  CLASSLIST_TEACHER_CURRENT_EMPTYINTRO: 'You do not have any current classes.',
  CLASSLIST_FACILITATOR_CURRENT_EMPTYINTRO: 'You do not have any current classes.',

  CLASSLIST_APPADMIN_CURRENT_EMPTYSUBHEADING: 'Once a class starts, it will appear here.',
  CLASSLIST_TEACHER_CURRENT_EMPTYSUBHEADING:
    'Once you create a class and the class starts, it will appear here.',
  CLASSLIST_FACILITATOR_CURRENT_EMPTYSUBHEADING:
    'Once a class that you are assigned to starts, it will appear here.',

  CLASSLIST_APPADMIN_PAST_INTRO:
    'The submission date for these classes has passed. Check curatorial review progress for these classes in the Reviews section.',
  CLASSLIST_TEACHER_PAST_INTRO: (
    <>
      <Typography
        variant='paragraph'
        sx={{
          mb: '1rem'
        }}
        component='p'
      >
        The submission date for these classes has passed. After our curators have reviewed student
        projects, you will be notified of any awarded photographs. Please check each class for
        details of awarded photographs and to help students upload forms.
      </Typography>
      <Typography
        variant='paragraph'
        sx={{
          mb: '1rem'
        }}
        component='p'
      >
        If you need to extend the end date for a class to allow more students to submit projects,
        please contact the <i>wrkxfmly</i> administrator.
      </Typography>
    </>
  ),
  CLASSLIST_FACILITATOR_PAST_INTRO: (
    <>
      <Typography
        variant='paragraph'
        sx={{
          mb: '1rem'
        }}
        component='p'
      >
        The submission date for these classes has passed. After our curators have reviewed student
        projects, you will be notified of any awarded photographs. Please check each class for
        details of awarded photographs and to help students upload forms.
      </Typography>
      <Typography
        variant='paragraph'
        sx={{
          mb: '1rem'
        }}
        component='p'
      >
        If you need to extend the end date for a class to allow more students to submit projects,
        please contact the <i>wrkxfmly</i> administrator.
      </Typography>
    </>
  ),

  CLASSLIST_APPADMIN_PAST_EMPTYINTRO: 'There are no past classes.',
  CLASSLIST_TEACHER_PAST_EMPTYINTRO: 'You do not have any past classes.',
  CLASSLIST_FACILITATOR_PAST_EMPTYINTRO: 'You do not have any past classes.',

  CLASSLIST_APPADMIN_PAST_EMPTYSUBHEADING:
    'Classes that have passed their submission date will appear here.',
  CLASSLIST_TEACHER_PAST_EMPTYSUBHEADING:
    'After your classes pass their submission date, they will appear here.',
  CLASSLIST_FACILITATOR_PAST_EMPTYSUBHEADING:
    'After your classes pass their submission date, they will appear here.',

  CLASSLIST_APPADMIN_UPCOMINGVISITS:
    'The following classes have teaching artist visits scheduled in the next 7 days.',
  CLASSLIST_TEACHER_UPCOMINGVISITS:
    'Your teaching artist is scheduled to visit the following class(es) in the next 7 days. If these dates are no longer accurate, please update visit dates in each class page.',
  CLASSLIST_FACILITATOR_UPCOMINGVISITS:
    'You are scheduled to visit the following class(es) in the next 7 days. If these dates are no longer accurate, please update visit dates in each class page.',

  CLASSLIST_APPADMIN_NOUPCOMINGVISITS:
    'There are no classes with scheduled teaching artist visits in the next 7 days.',
  CLASSLIST_TEACHER_NOUPCOMINGVISITS:
    'None of your classes have teaching artist visits scheduled in the next 7 days. If this is not accurate, please update visit dates in each class page.',
  CLASSLIST_FACILITATOR_NOUPCOMINGVISITS:
    'You are not scheduled to visit any classes in the next 7 days. If this is not accurate, please update visit dates in each class page.',

  CLASSLIST_APPADMIN_EMPTYSEARCHINTRO: 'There are no classes matching this search term.',
  CLASSLIST_APPADMIN_EMPTYSEARCHSUBHEADING:
    'Please try another search term, or search in a different class tab.',

  CLASSLIST_TEACHER_EMPTYSEARCHINTRO: 'There are no classes matching this search term.',
  CLASSLIST_TEACHER_EMPTYSEARCHSUBHEADING:
    'Please try another search term, or search in a different class tab.',

  CLASSLIST_FACILITATOR_EMPTYSEARCHINTRO: 'There are no classes matching this search term.',
  CLASSLIST_FACILITATOR_EMPTYSEARCHSUBHEADING:
    'Please try another search term, or search in a different class tab.',

  ERRORPAGE_PUBLIC_404_HEADING: 'We can’t find the page you are looking for.',
  ERRORPAGE_PUBLIC_404_SUBTEXT: (
    <>
      Please check the page URL and try again or return to the{' '}
      <a href='https://education.workingassumptions.org/'>homepage</a>.
    </>
  ),

  ERRORPAGE_PRIVATE_404_HEADING: 'We can’t find the page you are looking for.',
  ERRORPAGE_PRIVATE_404_SUBTEXT: 'Please check the page URL and try again.',

  ERRORPAGE_PUBLIC_403_HEADING: 'You are not authorized to view this page.',
  ERRORPAGE_PUBLIC_403_SUBTEXT: 'Please check the page URL and that you are correctly logged in.',

  ERRORPAGE_PRIVATE_403_HEADING: 'You are not authorized to view this page.',
  ERRORPAGE_PRIVATE_403_SUBTEXT: 'Please check the page URL and that you are correctly logged in.',

  CLASSDETAILS_APPADMIN_CLASSDATES_INTRO:
    'After you edit the class dates and save changes, the teacher and teaching artist will be notified.',
  CLASSDETAILS_TEACHER_CLASSDATES_INTRO: (
    <>
      If you need to change the start or submission date for this class, please contact the{' '}
      <i>wrkxfmly</i> administrator.
    </>
  ),
  CLASSDETAILS_FACILITATOR_CLASSDATES_INTRO: (
    <>
      If you need to change the start or submission date for this class, please contact the{' '}
      <i>wrkxfmly</i> administrator.
    </>
  ),

  CLASSDETAILS_APPADMIN_CLASSVISITDATES_INTRO:
    'Please ask the teacher and teaching artist to keep this information up-to-date.',
  CLASSDETAILS_TEACHER_CLASSVISITDATES_INTRO: (
    <>
      <Typography
        variant='paragraph'
        sx={{
          mb: '1rem'
        }}
        component='p'
      >
        These are the dates that your teaching artist will visit the class. Please speak to your
        teaching artist and keep this information up-to-date if your class schedule changes.
      </Typography>
      <Typography
        variant='paragraph'
        sx={{
          mb: '1rem'
        }}
        component='p'
      >
        After you edit these dates and save changes, the teaching artist and the <i>wrkxfmly</i>{' '}
        administrator will see the new dates next time they log in.
      </Typography>
    </>
  ),
  CLASSDETAILS_FACILITATOR_CLASSVISITDATES_INTRO: (
    <>
      <Typography
        variant='paragraph'
        sx={{
          mb: '1rem'
        }}
        component='p'
      >
        These are the dates that you are due to visit the class. Please speak to the teacher and
        keep this information up-to-date if your schedule changes.
      </Typography>
      <Typography
        variant='paragraph'
        sx={{
          mb: '1rem'
        }}
        component='p'
      >
        When you edit these dates and save changes, the teacher and the <i>wrkxfmly</i>{' '}
        administrator will see the new dates next time they log in.
      </Typography>
    </>
  ),

  CLASSDETAILS_APPADMIN_EXTRACLASSVISITDATE_INTRO:
    'Large classes may need more time with the teaching artist. You can enable an extra visit for this below. Teachers and teaching artists will be notified by email and will see the extra visit next time they log in.',
  CLASSDETAILS_TEACHER_EXTRACLASSVISITDATE_INTRO: (
    <>
      An extra visit has been approved for the class. If you no longer need this visit, please
      contact the <i>wrkxfmly</i> administrator.
    </>
  ),
  CLASSDETAILS_FACILITATOR_EXTRACLASSVISITDATE_INTRO: (
    <>
      An extra visit has been approved for the class. If you no longer need this visit, please
      contact the <i>wrkxfmly</i> administrator.
    </>
  ),

  CLASSDETAILS_EMPTYSTUDENTLIST_HEADING: 'This class does not have any students yet.',
  CLASSDETAILS_EMPTYSTUDENTLIST_SUBHEADING:
    'Use the ‘Get Invite Link’ button to invite students to join the class.',

  CLASSDETAILS_STUDENTLIST_CURRENT_CLASS:
    'Use this list to track class progress as students work on their projects.',

  CLASSDETAILS_STUDENTLIST_PAST_CLASS:
    'This class has ended. Use this list to review student work and to see awarded photographs after the class is reviewed.',

  CLASSDETAILS_APPADMIN_ADMINISTRATIVE_NOTES_INTRO: (
    <>
      <Typography variant='paragraph' component='p'>
        Please enter additional notes about this class, such as:
      </Typography>

      <ul>
        <li>Did the class visits take place in person or remotely?</li>
        <li>Was the class submission date extended?</li>
        <li>Were there any logistical challenges for this class?</li>
      </ul>

      <Typography variant='paragraph' component='p'>
        These notes help Working Assumptions keep track of program history and are only seen by
        other <i>wrkxfmly</i> administrators, not by the class teacher or teaching artist.
      </Typography>
    </>
  ),
  CLASSDETAILS_APPADMIN_CLASS_APPROVAL_INTRO: (
    <>
      <Typography variant='paragraph' component='p'>
        This class was created by a teacher and must be approved before students can join. Please
        complete the following steps:
      </Typography>

      <ol>
        <li>Check that the class start and submission dates are correct.</li>
        <li>Assign a teaching artist to support the class.</li>
        <li>Assign curator(s) to review the class.</li>
      </ol>

      <Typography variant='paragraph' component='p'>
        Then, check the box below, and click ‘Save Changes’.
      </Typography>
      <Typography variant='paragraph' component='p'>
        &nbsp;
      </Typography>
      <Typography variant='paragraph' component='p'>
        The teacher will be notified that the class is approved. The teaching artist will be
        notified that they were assigned to the class. The curator(s) will not be notified
        immediately, but will see the class dates next time they log in.
      </Typography>
    </>
  ),

  CLASSDETAILS_APPADMIN_AWARDRESULT_INTRO:
    'Remind these students to log in and provide the forms required for publication of their work.',
  CLASSDETAILS_TEACHER_AWARDRESULT_INTRO:
    'Remind these students to log in and provide the forms required for publication of their work.',
  CLASSDETAILS_FACILITATOR_AWARDRESULT_INTRO:
    'Remind these students to log in and provide the forms required for publication of their work.',

  CLASSDETAILS_FACILITATOR_CLASSDETAILS_CLASS_END_NOTICE: (
    <>
      This class has ended. If you want to edit information or to change the class dates, please
      contact the <i>wrkxfmly</i> administrator.
    </>
  ),
  CLASSDETAILS_TEACHER_CLASSDETAILS_CLASS_END_NOTICE: (
    <>
      This class has ended. If you want to edit information or to change the class dates, please
      contact the <i>wrkxfmly</i> administrator.
    </>
  ),

  CLASSDETAILS_FACILITATOR_STUDENTLIST_CLASS_END_NOTICE: (
    <>
      This class has ended. Use this list to review student work and to see awarded photographs
      after the class is reviewed.
    </>
  ),
  CLASSDETAILS_TEACHER_STUDENTLIST_CLASS_END_NOTICE: (
    <>
      This class has ended. Use this list to review student work and to see awarded photographs
      after the class is reviewed.
    </>
  ),

  CLASSDETAILS_ALL_ADD_CURATORS_TEXT: (
    <>
      <Typography
        variant='paragraph'
        sx={{
          mb: '1rem'
        }}
        component='p'
      >
        Select one or more curators to review this class.
      </Typography>
      <Typography
        variant='paragraph'
        sx={{
          mb: '1rem'
        }}
        component='p'
      >
        After you confirm your choice, please save the changes on the Class Details page. The
        curator(s) will see the estimated review dates for this class when they next log in.
      </Typography>
    </>
  ),
  CLASSDETAILS_ALL_ADD_FACILITATOR_TEXT: (
    <>
      <Typography
        variant='paragraph'
        sx={{
          mb: '1rem'
        }}
        component='p'
      >
        Select the teaching artist who will support this class.
      </Typography>
      <Typography
        variant='paragraph'
        sx={{
          mb: '1rem'
        }}
        component='p'
      >
        After you confirm your choice, please save the changes on the Class Details page. The
        teaching artist will receive an email with the class details and will see the class when
        they next log in.
      </Typography>
    </>
  ),
  USERLIST_APPADMIN_STUDENTLIST_EMPTYINTRO: 'There are no students matching this search term.',
  USERLIST_APPADMIN_STUDENTLIST_EMPTYSUBHEADING:
    'Please try another search term, or search in a different user tab.',
  USERLIST_APPADMIN_TEACHERLIST_EMPTYINTRO: 'There are no teachers matching this search term.',
  USERLIST_APPADMIN_TEACHERLIST_EMPTYSUBHEADING:
    'Please try another search term, or search in a different user tab.',

  REVIEW_DEFAULT_APPADMIN_INPROGRESS_EMPTYINTRO: 'There are no classes.',
  REVIEW_DEFAULT_APPADMIN_INPROGRESS_EMPTYSUBHEADING: 'Please try in a different review tab.',

  REVIEW_APPADMIN_INPROGRESS_EMPTYINTRO: 'There are no reviews matching this search term.',
  REVIEW_APPADMIN_INPROGRESS_EMPTYSUBHEADING:
    'Please try another search term, or search in a different tab.',

  SCHOOL_DEFAULT_APPADMIN_LIST_EMPTYINTRO: 'There are no school.',
  SCHOOL_DEFAULT_APPADMIN_LIST_EMPTYSUBHEADING: 'No school found.',

  SCHOOL_APPADMIN_LIST_EMPTYINTRO: 'There are no schools matching this search term.',
  SCHOOL_APPADMIN_LIST_EMPTYSUBHEADING: 'Please try another search term.',

  TEACHER_APPADMIN_CLASSLIST_EMPTYINTRO: 'This teacher has no classes.',
  TEACHER_APPADMIN_CLASSLIST_EMPTYSUBHEADING:
    'Once you or the teacher create classes, the classes will appear here.',

  FACILITATOR_APPADMIN_CLASSLIST_EMPTYINTRO: 'This teaching artist has no classes.',
  FACILITATOR_APPADMIN_CLASSLIST_EMPTYSUBHEADING:
    'Once the teaching artist is assigned to classes, the classes will appear here.',

  SCHOOL_APPADMIN_CLASSLIST_EMPTYINTRO: 'This school has no classes.',
  SCHOOL_APPADMIN_CLASSLIST_EMPTYSUBHEADING:
    'Once you or a teacher create classes for this school, the classes will appear here.',

  CURATOR_CURATOR_REVIEW_EMPTYINTRO: 'This curator has no reviews.',
  CURATOR_CURATOR_REVIEW_EMPTYSUBHEADING:
    'Once the curator is assigned to reviews, the reviews will appear here.',

  REVIEW_CURATOR_INPROGRESS_EMPTYINTRO: 'There are no reviews matching this search term.',
  REVIEW_CURATOR_INPROGRESS_EMPTYSUBHEADING: 'Please try another search term.',

  PHOTOGRAPH_DETAIL_APPADMIN_MODELS_INTRO:
    'If this photograph requires Model Release(s), add one model for each recognizable person whose likeness requires a release. The photograph cannot be published until each model has a granted Model Release that has been approved by Working Assumptions.',
  PHOTOGRAPH_DETAIL_TEACHER_MODELS_INTRO:
    'Working Assumptions does not require a Model Release for this photograph.',
  PHOTOGRAPH_DETAIL_CURATOR_MODELS_INTRO:
    'Working Assumptions does not require a Model Release for this photograph.',
  PHOTOGRAPH_DETAIL_FACILITATOR_MODELS_INTRO:
    'Working Assumptions does not require a Model Release for this photograph.',
  PHOTOGRAPH_DETAIL_STUDENT_MODELS_INTRO:
    'Working Assumptions does not require a Model Release for this photograph.',
  PROJECTLIST_STUDENT_INTRO: (
    <>
      {' '}
      When your first class begins, you will see your projects for the <i>wrkxfmly</i>assignment
      here.
    </>
  )
}

const STATUS_TEXT = {
  CLASSLIST_APPROVECLASS: {
    statusType: 'alert',
    icon: 'AlertTriangle',
    statusText: 'Approve Class'
  },
  CLASSLIST_PENDINGAPPROVAL: {
    statusType: 'warning',
    icon: 'Clock',
    statusText: 'Wait for Class Approval'
  },
  CLASSLIST_ADDVISIT: {
    statusType: 'alert',
    icon: 'AlertTriangle',
    statusText: 'Add Class Visits'
  },
  CLASSLIST_NEWVISIT: {
    statusType: 'success',
    icon: 'CheckCircle',
    statusText: 'New Visit Dates'
  },
  CLASSLIST_INVITESTUDENT: {
    statusType: 'alert',
    icon: 'AlertTriangle',
    statusText: 'Invite Students'
  },
  CLASSLIST_WAITINGSUMMARY: {
    statusType: 'warning',
    icon: 'Clock',
    statusText: 'Wait for Review Summary'
  },
  CLASSLIST_AWARDAVAILABLE: {
    statusType: 'success',
    icon: 'CheckCircle',
    statusText: 'Award Details Available'
  },

  CLASSDETAILS_STUDENTLIST_REMIND_SHORTLIST: {
    statusType: 'alert',
    icon: 'AlertTriangle',
    statusText: 'Remind to Build Shortlist'
  },
  CLASSDETAILS_STUDENTLIST_REMIND_SUBMIT: {
    statusType: 'alert',
    icon: 'AlertTriangle',
    statusText: 'Remind to Submit Project'
  },
  CLASSDETAILS_STUDENTLIST_SUBMITTED: {
    statusType: 'success',
    icon: 'CheckCircle',
    statusText: 'Project Submitted'
  },
  CLASSDETAILS_STUDENTLIST_NOT_SUBMITTED: {
    statusType: 'grey-warning',
    icon: 'Slash',
    statusText: 'Project Not Submitted'
  },
  CLASSDETAILS_STUDENTLIST_PHOTO_AWARDED: {
    statusType: 'warning',
    icon: 'Award',
    statusText: 'PHOTO_COUNT Awarded Photograph(s)'
  },
  CLASSDETAILS_STUDENTLIST_NO_PHOTO_AWARDED: {
    statusType: 'no-awarded-photo',
    icon: 'Info',
    statusText: 'No Awards'
  },
  CLASSDETAILS_STUDENTLIST_PENDING_FORMS: {
    statusType: 'alert',
    icon: 'AlertTriangle',
    statusText: 'Remind to Provide Forms'
  },
  CLASSDETAILS_STUDENTLIST_NO_FINAL_PHOTOS: {
    statusType: 'alert',
    icon: 'AlertTriangle',
    statusText: 'No Final Photographs Chosen'
  }
}

export const getTextContentByPage = (pageName, role, textType, tabName = '') => {
  let contentRequiredKey = pageName + '_' + role + '_'
  if (tabName !== '') {
    contentRequiredKey = contentRequiredKey + tabName + '_'
  }
  contentRequiredKey = contentRequiredKey + textType
  return TEXT_CONTENT[contentRequiredKey]
}

export const getStatusesForPage = (pageName, pageData, userStore) => {
  let pageStatuses = []
  const finalStatusObj = []
  if (pageName === 'CLASSLIST') {
    pageStatuses = getStatusesForClassList(pageData, userStore)
  }

  if (pageName === 'CLASSDETAILS_STUDENTLIST') {
    pageStatuses = getStatusesForClassDetailsStudentList(pageData, userStore)
  }

  pageStatuses.forEach(requiredStatus => {
    let statusObj = pageName + '_' + requiredStatus
    let statusData = STATUS_TEXT[statusObj]
    if (statusObj === 'CLASSDETAILS_STUDENTLIST_PHOTO_AWARDED') {
      const awardsCount = pageData.photosCounter.awarded
      let statusText = statusData.statusText
      statusText = statusText.includes('PHOTO_COUNT')
        ? statusText.replace('PHOTO_COUNT', awardsCount)
        : statusText.replace(/\d+/g, awardsCount)
      statusData.statusText = statusText
    }

    finalStatusObj.push(
      getStatusInFormat(statusData.statusType, statusData.icon, statusData.statusText)
    )
  })

  return finalStatusObj
}

const getStatusesForClassList = (cohort, userStore) => {
  let classStatus = []
  const { role, lastSignOutAt } = userStore
  const endDate = DateUtils.createMoment(cohort.endDate).hours(23).minutes(59).seconds(59)

  if (!cohort.approved) {
    if (['teacher', 'facilitator'].includes(role)) {
      classStatus.push('PENDINGAPPROVAL')
    } else {
      classStatus.push('APPROVECLASS')
    }
  } else if (
    cohort.students &&
    cohort.students.length === 0 &&
    moment(DateUtils.createMoment().toString()).isBefore(
      endDate
    ) &&
    moment(DateUtils.createMoment().toString()).isAfter(moment(cohort.startDate))
  ) {
    classStatus.push('INVITESTUDENT')
  }
  if (
    cohort.allVisitsScheduled === null &&
    moment(DateUtils.createMoment().toString()).isBefore(
      endDate
    )
  ) {
    classStatus.push('ADDVISIT')
  }

  if (
    moment(DateUtils.createMoment().toString()).isBefore(
      endDate
    ) &&
    ((cohort.introVisit && moment(cohort.introVisit.createdAt).isAfter(moment(lastSignOutAt))) ||
      (cohort.midVisit && moment(cohort.midVisit.createdAt).isAfter(moment(lastSignOutAt))) ||
      (cohort.finalVisit && moment(cohort.finalVisit.createdAt).isAfter(moment(lastSignOutAt))) ||
      (cohort.secondMidVisit &&
        moment(cohort.secondMidVisit.createdAt).isAfter(moment(lastSignOutAt))))
  ) {
    classStatus.push('NEWVISIT')
  }

  if (
    moment(DateUtils.createMoment().toString()).isAfter(
      endDate
    ) &&
    ((['appadmin'].includes(role) && !cohort.reviewed) ||
      (['teacher', 'facilitator'].includes(role) && !cohort.summarySent))
  ) {
    classStatus.push('WAITINGSUMMARY')
  }
  if (
    moment(DateUtils.createMoment().toString()).isAfter(
      endDate
    ) &&
    ((['appadmin'].includes(role) && cohort.reviewed) ||
      (['teacher', 'facilitator'].includes(role) && cohort.summarySent))
  ) {
    classStatus.push('AWARDAVAILABLE')
  }
  return classStatus
}

const getStatusesForClassDetailsStudentList = (project, userStore) => {
  let projectStatus = []
  const { cohort } = project
  const { reviewed, summarySent } = cohort
  const endDate = DateUtils.createMoment(cohort.endDate).hours(23).minutes(59).seconds(59)
  const awardsCount = project.photosCounter.awarded
  const { role } = userStore

  const submitted = project.submitted
  const shortListedPhotos = project.photosCounter.shortlist

  if (submitted) {
    if (
      (['appadmin', 'curator'].includes(role) && !reviewed) ||
      (['facilitator', 'teacher'].includes(role) && !summarySent)
    ) {
      projectStatus.push('SUBMITTED')
    } else if (
      (['appadmin', 'curator'].includes(role) && reviewed) ||
      (['facilitator', 'teacher'].includes(role) && summarySent)
    ) {
      if (awardsCount > 0) {
        projectStatus.push('PHOTO_AWARDED')
      } else {
        projectStatus.push('NO_PHOTO_AWARDED')
      }
    }
  } else if (
    !submitted &&
    moment(DateUtils.createMoment().toString()).isAfter(
      endDate
    )
  ) {
    projectStatus.push('NOT_SUBMITTED')
  } else if (
    !submitted &&
    DateUtils.pendingTimeToEndDate(endDate)
  ) {
    if (project.photosCounter.uploaded > 0 && shortListedPhotos === 0) {
      projectStatus.push('REMIND_SHORTLIST')
    } else if (
      shortListedPhotos > 0 &&
      endDate.diff(DateUtils.createMoment(), 'days') <= 7
    ) {
      projectStatus.push('REMIND_SUBMIT')
    }
  }

  if (summarySent) {
    if (project.photosCounter.missing_forms > 0) {
      projectStatus.push('PENDING_FORMS')
    }
  }

  return projectStatus
}

const getStatusInFormat = (statusType, icon, statusText) => {
  let statusClassName = 'status__' + statusType
  return (
    <div className={statusClassName} key={Math.floor(Math.random() * Math.random())}>
      {getFormattedStatus(icon, statusText)}
    </div>
  )
}
