import React, { Component } from 'react'
import UseAgreementTermsGuardianModal from './UseAgreementTermsGuardianModal'
import { Typography } from '@mui/material'
import './projectProgressPublicationSection.scss'
import FormCheckbox from '../elements/FormCheckbox'
import Button from '../elements/Button.js'
import { waPrivateCipher } from '../../utils/commonFunctions'
import UseAgreementModal from './UseAgreementModal'
import { Send } from 'react-feather'
import SendReminderNotificationModal from './SendReminderNotificationModal'
import GATag from '../../utils/GATag'
import UaAgreementModal from './UaAgreementModal'
import { Link } from 'react-router-dom'

class ProjectPorgressPublicationSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      useAgreementTermsGuardianModal: false,
      publishablePhotoIds: [],
      showUseAgreementModal: false,
      awardedPhotos: props.photos.filter(photo => photo.awards.length > 0),
      allPhotos: false,
      encIds: '',
      anyPhotoHasUa: this.checkIfAnyPhotoHasUa(props.photos),
      showMissingFormsModal: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      awardedPhotos: nextProps.photos.filter(photo => photo.awards.length > 0),
      anyPhotoHasUa: this.checkIfAnyPhotoHasUa(nextProps.photos)
    })
  }

  checkIfAnyPhotoHasUa = photos => {
    let photosCountWithUa = photos.filter(photo => photo.useAgreements.length > 0)
    return photosCountWithUa.length > 0
  }

  handleFormChangeCheck = e => {
    let ids = this.state.publishablePhotoIds
    let allCheck = this.state.allPhotos
    if (e.target.name === 'allPhotos') {
      if (e.target.checked) {
        ids = this.state.awardedPhotos.map(d => d.id)
        allCheck = true
      } else {
        ids = []
        allCheck = false
      }
    } else {
      if (e.target.checked) {
        ids.push(e.target.name)
      } else {
        var elementIndex = ids.indexOf(e.target.name)
        if (elementIndex > -1) {
          ids.splice(elementIndex, 1)
        }
        allCheck = false
      }
    }
    let encryptedIds = waPrivateCipher(ids.join('-'))
    this.setState({
      [e.target.name]: e.target.checked,
      publishablePhotoIds: [...ids],
      allPhotos: this.state.awardedPhotos.length === ids.length ? true : allCheck,
      encIds: encryptedIds
    })
  }

  setGA = () => {
    GATag.setEvent({
      category: 'use-agreement',
      action: 'open-multiple',
      label: 'open multiple',
      value: 1
    })
  }

  render() {
    const { role, photos, isStudentMinor } = this.props
    const awardedPhotos = photos.filter(photo => photo.awards.length > 0)
    let closeUaModal = () =>
      this.setState({
        showUseAgreementModal: false
      })
    return (
      <div className='publication-section'>
        <div className='publication-section__heading'>
          <Typography variant='h2' component='h2'>
            Next Steps{role === 'student' ? ' for Your Work' : ''}
          </Typography>
        </div>
        <div className='publication-section__body'>
          <div className='publication-section__body--descriptions'>
            {role !== 'student' ? (
              <Typography variant='paragraph' component='p'>
                For the student’s valuable contribution to the <i>wrkxfmly</i> project, we would like to preserve and use their awarded photograph(s). You can read about how we do this in our{' '}
                <Typography
                  component='span'
                  variant='link'
                  className='large'
                  onClick={() =>
                    this.setState({
                      useAgreementTermsGuardianModal: true
                    })
                  }
                >
                  Use Agreement
                </Typography>
                .
              </Typography>
            ) : (
              <Typography variant='paragraph' component='p'>
                For your valuable contribution to the <i>wrkxfmly</i> project, Working Assumptions would like to preserve and use your awarded photograph(s). You can read about how we do this in our{' '}
                <Typography
                  component='span'
                  variant='link'
                  className='large'
                  onClick={() =>
                    this.setState({
                      useAgreementTermsGuardianModal: true
                    })
                  }
                >
                  Use Agreement
                </Typography>
                .
              </Typography>
            )}
          </div>
          {!this.state.anyPhotoHasUa ? (
            <>
              <div className='publication-section__body--descriptions'>
                {role !== 'student' ? (
                  <Typography variant='paragraph' component='p'>
                    If the student is unable to select the photograph(s) that they want Working Assumptions to use, you can make the selection(s) on their behalf by clicking the options below.
                  </Typography>
                ) : (
                  <>
                    <Typography variant='paragraph' component='p'>
                      Please select the photograph(s) that you will permit Working Assumptions to use.
                    </Typography>
                    <Typography variant='paragraph' component='p'>
                      If any of these photographs feature a recognizable person, we will also ask you to provide Model Releases later.
                    </Typography>
                  </>
                )}
              </div>
              <div>
                <FormCheckbox
                  label={<>Grant permission for all awarded photographs.</>}
                  color='primary'
                  name='allPhotos'
                  checked={this.state.allPhotos}
                  onChange={this.handleFormChangeCheck}
                  style={{ marginTop: 10 }}
                />
                {awardedPhotos &&
                  awardedPhotos.map(photo => {
                    return (
                      <div key={photo.id} class='publication-section__checkbox'>
                        <FormCheckbox
                          label={<>Grant permission for {photo.title}.</>}
                          color='primary'
                          name={photo.id}
                          checked={this.state.publishablePhotoIds.includes(photo.id)}
                          onChange={this.handleFormChangeCheck}
                          style={{ marginTop: 10 }}
                        />
                      </div>
                    )
                  })}
              </div>
              <div className='publication-section__body--sub-descriptions'>
                {role !== 'student' ? (
                  isStudentMinor ? (
                    <Typography variant='paragraph' component='p'>
                      Because the student is under 18, you must ask their parent or legal guardian to grant permission to use the selected photograph(s). You can submit the Use Agreement form for the selected photograph(s) on behalf of the student by clicking the button below.
                    </Typography>
                  ) : (
                    <Typography variant='paragraph' component='p'>
                      You can submit the Use Agreement form for the selected photograph(s) on behalf 
                      of the student by clicking the button below.
                    </Typography>
                  )
                ) : isStudentMinor ? (
                  <Typography variant='paragraph' component='p'>
                    Because you are under 18, you must ask your parent or legal guardian to grant 
                    permission to use the selected photograph(s). Please add or send a Use 
                    Agreement for the photograph(s) below.
                  </Typography>
                ) : (
                  <Typography variant='paragraph' component='p'>
                    Please click below to fill out a digital Use Agreement form for the selected photograph(s). The form will open in a new window.
                  </Typography>
                )}
              </div>
              <div className='publication-section__body--descriptions'>
                {isStudentMinor || ['appadmin', 'teacher', 'facilitator'].includes(role) ? (
                  <Button
                    variant='contained'
                    title='Add a Use Agreement'
                    onClick={() => {
                      // window.ga('send', 'event', 'use-agreement', 'open-multiple', 1)
                      this.setGA()
                      this.setState({ showUseAgreementModal: true })
                    }}
                    disabled={this.state.publishablePhotoIds.length < 1}
                  />
                ) : (
                  <>
                    {this.state.publishablePhotoIds.length > 0 ? (
                      // <a
                      //   className='btn btn-primary'
                      //   rel='noopener noreferrer'
                      //   href={`/self-use-agreement/${this.state.encIds}`}
                      //   target='_blank'
                      // >
                      //   Fill Out Use Agreement
                      // </a>
                      <Link
                        to={`/self-use-agreement/${this.state.encIds}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <Button
                          variant='contained'
                          title='Fill Out Use Agreement'
                          disabled={false}
                        />
                      </Link>
                    ) : (
                      <Button
                        variant='contained'
                        title='Fill Out Use Agreement'
                        // onClick={() => this.setState({ showUseAgreementModal: true })}
                        disabled={true}
                      />
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className='publication-section__sub-sections'>
                <div className='publication-section__sub-sections--heading'>
                  <Typography variant='h3' className='semi-strong'>
                    Use Agreements
                  </Typography>
                </div>
                <div>
                  {role !== 'student' ? (
                    <>
                      <Typography variant='paragraph' component='p'>
                        Check the awarded photograph(s) below to see the status of the Use
                        Agreement(s).
                      </Typography>
                      <Typography variant='paragraph' component='p'>
                        You or the student can also add a new Use Agreement to each photograph if
                        needed.
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant='paragraph' component='p'>
                        Check your awarded photographs below to see the status of your Use
                        Agreement(s).
                      </Typography>
                      <Typography variant='paragraph' component='p'>
                        You can also add a new Use Agreement to each photograph if needed.
                      </Typography>
                    </>
                  )}
                </div>
              </div>
              <div className='publication-section__sub-sections'>
                <div className='publication-section__sub-sections--heading'>
                  <Typography variant='h3' className='semi-strong'>
                    Model Releases
                  </Typography>
                </div>
                <div>
                  {role !== 'student' ? (
                    <>
                      <Typography variant='paragraph' component='p'>
                        Please check if any awarded photographs below require a Model Release. You
                        or the student can add a Model Release on each photograph page.
                      </Typography>
                      {['teacher', 'facilitator'].includes(role) ? (
                        <Typography variant='paragraph' component='p'>
                          Model Releases and Use Agreements are reviewed by Working Assumptions
                          before photographs are published. If any of the forms are not approved,
                          the student will receive an email asking them to try again.
                        </Typography>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <Typography variant='paragraph' component='p'>
                        Please check if any awarded photographs below require a Model Release. You
                        can add a Model Release on each photograph page.
                      </Typography>
                      <Typography variant='paragraph' component='p'>
                        Model Releases and Use Agreements are reviewed by Working Assumptions before
                        photographs are published. If any of your forms are not approved, you will
                        receive an email asking you to try again.
                      </Typography>
                    </>
                  )}
                </div>
              </div>
              {role === 'appadmin' ? (
                <div className='publication-section__sub-sections'>
                  <div className='publication-section__sub-sections--heading'>
                    <Typography variant='h3' className='semi-strong'>
                      Form Review
                    </Typography>
                  </div>
                  <div>
                    <Typography variant='paragraph' component='p'>
                      Please review all Model Releases and Use Agreements attached to the
                      photograph(s) below. You must approve or reject each form before a photograph
                      can be published.
                    </Typography>
                    <Typography variant='paragraph' component='p'>
                      If any forms are incorrect or missing, you can send a reminder to the student.
                    </Typography>
                  </div>
                </div>
              ) : null}

              {role === 'appadmin' ? (
                <div>
                  <>
                    <Button
                      variant='outlined'
                      title={
                        <span className='icon-text-span'>
                          <Send size={17} />
                          Send Missing Forms Reminder
                        </span>
                      }
                      onClick={() =>
                        this.setState({
                          showMissingFormsModal: true
                        })
                      }
                    />
                    <SendReminderNotificationModal
                      show={this.state.showMissingFormsModal}
                      onHide={() => this.setState({ showMissingFormsModal: false })}
                      projectId={this.props.projectId}
                      showToast={this.props.showToast}
                    />
                  </>
                </div>
              ) : null}
            </>
          )}
        </div>
        <UaAgreementModal
          open={this.state.useAgreementTermsGuardianModal}
          handleClose={() =>
            this.setState({ ...this.state, useAgreementTermsGuardianModal: false })
          }
          isMinor={isStudentMinor}
        />
        <UseAgreementModal
          show={this.state.showUseAgreementModal}
          onHide={closeUaModal}
          modelOp='add'
          photoIds={this.state.publishablePhotoIds}
          onSuccessCallback={closeUaModal}
          refetchQueries={this.props.refetchQueries}
          refetchPhoto={this.props.refetchPhoto}
          studentDetails={{ student: { ...this.props.studentDetails } }}
          isStudentMinor={isStudentMinor}
        />
      </div>
    )
  }
}

export default ProjectPorgressPublicationSection
