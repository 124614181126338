import React from 'react'

import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
  Navigate,
  Outlet
} from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { routes, unprotectedRoutes } from '../../utils/routes.js'

const PrivateRoute = ({ isAuth, roleRes, children }) => {
  if (!isAuth) {
    return <Navigate to='/login' />
  }
  if (!roleRes) {
    return <Navigate to='/403' />
  }
  return children ? children : <Outlet />
}

class Navigation extends React.Component {
  constructor(...args) {
    super(...args)
    this.attachRef = target => this.setState({ target })
    this.state = {
      show: false,
      selected: `${window.location.pathname}`,
      privatePages: routes.map(path => path.path.split('/')[1]),
      targetPath: window.location.pathname.split('/')[1]
    }
  }

  componentWillMount() {
    const { userStore } = this.props
    if (userStore.isAuthenticated && this.state.targetPath === '') {
      userStore.role === 'student'
        ? (window.location.pathname = '/projects')
        : userStore.role === 'teacher' || userStore.role === 'facilitator'
        ? (window.location.pathname = '/classes')
        : userStore.role === 'curator'
        ? (window.location.pathname = '/reviews')
        : (window.location.pathname = '/dashboard')
    }

    if (userStore.isAuthenticated) {
      return import('./navigation.scss')
    } else {
      return import('./publicnavigtion.scss')
    }
  }

  render() {
    const { userStore } = this.props
    const { isAuthenticated, role } = userStore
    return (
      <Router>
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                element={
                  <PrivateRoute
                    isAuth={isAuthenticated}
                    roleRes={route && route.roles.includes(role)}
                  />
                }
              >
                {route.child && route.child.length > 0 ? (
                  <Route path={route.path} key={index}>
                    {route.child.map((childRoute, childIndex) => (
                      <Route
                        path={childRoute.path}
                        key={childIndex}
                        exact={childRoute.exact}
                        Component={childRoute.component}
                      />
                    ))}
                  </Route>
                ) : (
                  <Route
                    path={route.path}
                    key={index}
                    exact={route.exact}
                    Component={route.component}
                  />
                )}
              </Route>
            )
          })}
          {unprotectedRoutes.map((route, index) => {
            return (
              <Route key={index}>
                {route.child && route.child.length > 0 ? (
                  <Route path={route.path} key={index}>
                    {route.child.map((childRoute, childIndex) => (
                      <Route
                        path={childRoute.path}
                        key={childIndex}
                        exact={childRoute.exact}
                        Component={childRoute.component}
                      />
                    ))}
                  </Route>
                ) : (
                  <Route
                    path={route.path}
                    key={index}
                    exact={route.exact}
                    Component={route.component}
                  />
                )}
              </Route>
            )
          })}
        </Switch>
      </Router>
    )
  }
}

export default inject('uiStore', 'userStore')(observer(Navigation))
